import React, { useEffect, useState } from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';

function Home() {
  const [leagues, setLeagues] = useState([
    { id: '39', name: 'Premier League', backgroundImage: 'url("/images/england.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '140', name: 'La Liga', backgroundImage: 'url("/images/spain.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '135', name: 'Serie A', backgroundImage: 'url("/images/italy.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '78', name: 'Bundesliga', backgroundImage: 'url("/images/germany.jpg")', nextGamesDate: 'Finding live games...' },
    { id: '61', name: 'Ligue 1', backgroundImage: 'url("/images/france.jpg")', nextGamesDate: 'Finding live games...' }
  ]);

  const [loadingText, setLoadingText] = useState('Finding live games');

  useEffect(() => {
    const loadingInterval = setInterval(() => {
      setLoadingText(prev => {
        if (prev.endsWith('...')) return 'Finding live games';
        return prev + '.';
      });
    }, 500); // Change every 500ms

    return () => clearInterval(loadingInterval); // Clean up on unmount
  }, []);

  useEffect(() => {
    fetchNextGamesDate();
  }, []);

  const fetchNextGamesDate = async () => {
    const promises = leagues.map(async (league) => {
      const nextGamesDate = await findNextGamesDate(league.id);
      return { ...league, nextGamesDate };
    });

    const updatedLeagues = await Promise.all(promises);
    setLeagues(updatedLeagues);
  };

  const findNextGamesDate = async (leagueId) => {
    let currentDate = new Date();
    while (true) {
      const formattedDate = currentDate.toISOString().split('T')[0];
      const gamesAvailable = await checkGamesAvailability(leagueId, formattedDate);
      if (gamesAvailable) {
        return formattedDate;
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
  };

  const checkGamesAvailability = async (leagueId, date) => {
    const response = await fetch(`https://v3.football.api-sports.io/fixtures?date=${date}&league=${leagueId}&season=2024`, {
      method: 'GET',
      headers: {
        'x-rapidapi-host': 'v3.football.api-sports.io',
        'x-rapidapi-key': '4b8a8edb4c304fc2d5dbeda1bbf769d2' // Replace with your actual API key
      }
    });
    const data = await response.json();
    return data.response.length > 0;
  };

  const today = new Date().toISOString().split('T')[0];

  return (
    <div>
      <HeroSection />
      <div id="games-container" style={{ width: '100%', overflow: 'hidden', margin: '0', padding: '0' }}>
        {leagues.map((league) => (
          <div key={league.id} style={{ position: 'relative', backgroundImage: league.backgroundImage, backgroundSize: 'cover', backgroundPosition: '50% 50%', marginBottom: '0', border: '3px solid black' }}>
            {league.nextGamesDate === 'Finding live games...' ? (
              <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '5px' }}>
                {loadingText}
              </div>
            ) : league.nextGamesDate !== today ? (
              <div style={{ position: 'absolute', top: '10px', left: '50%', transform: 'translateX(-50%)', backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '5px' }}>
                Next game available is on {league.nextGamesDate}
              </div>
            ) : null}
            <iframe
              width="100%"
              src={league.nextGamesDate && league.nextGamesDate !== 'Finding live games...' ? `games.html?league=${league.id}&season=2024&date=${league.nextGamesDate}` : 'about:blank'}
              frameBorder="0"
              style={{ height: '500px', overflow: 'hidden', backgroundSize: 'cover' }}
              title={`league-${league.id}`}
            ></iframe>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Home;
